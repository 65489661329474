<template>
  <loading v-if="loading" />
  <switch-selector
    v-else
    :filterable="false"
    :data="mappedDelegates"
    :selected="newSelection"
    :disabled="isProcessing"
    @select="update"
  />
</template>

<script>
export default {
  name: "ManageParticipants",
  props: {
    taskId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      isProcessing: false,
      newSelection: {}
    };
  },
  computed: {
    task() {
      return this.$store.getters["tasks/task"](this.taskId);
    },
    siteRoles() {
      return this.$store.getters["sites/siteRoles"](this.task.siteId);
    },
    mappedDelegates() {
      return this.$_(this.siteRoles)
        .filter(role => role.role === "delegate")
        .map(role => {
          return {
            id: role.userId,
            label: role.email || "--",
            status: role.isResellerClient ? "reseller-client" : "delegate"
          };
        })
        .toArray()
        .value();
    },
    oldSelection() {
      const result = {};
      this.task.participants.forEach(i => {
        const user = this.$_(this.siteRoles).find(d => d.userId === i);
        if (user) this.$set(result, i, true);
      });

      return result;
    },
    oldSelectionIds() {
      return this.$_(this.oldSelection)
        .pickBy(selected => !!selected)
        .keys()
        .value();
    },
    newSelectionIds() {
      return this.$_(this.newSelection)
        .pickBy(selected => !!selected)
        .keys()
        .value();
    },
    hasNewSelection() {
      return !this.$_.isEqual(this.newSelectionIds, this.oldSelectionIds);
    }
  },

  created() {
    this.loading++;
    this.$store
      .dispatch("tasks/observeTask", { taskId: this.taskId })
      .then(() => {
        return this.$store.dispatch("sites/observeRoles", {
          siteId: this.task.siteId
        });
      })
      .finally(() => {
        this.$set(this, "newSelection", this.$_.clone(this.oldSelection));
        this.loading--;
      });
  },
  beforeDestroy() {
    this.$store.dispatch("sites/unobserveRoles", {
      siteId: this.task.siteId
    });
    this.$store.dispatch("tasks/binTask", { taskId: this.taskId });
  },
  methods: {
    update() {
      this.isProcessing = true;
      this.$store
        .dispatch("tasks/updateParticipants", {
          taskId: this.taskId,
          users: this.newSelectionIds
        })
        .then(result => {
          this.isProcessing = false;
          this.$emit("close");
          this.$toast.open({
            message: result.message
          });
        })
        .catch(error => {
          this.isProcessing = false;
          this.$toast.open({
            message: `${error.message}`,
            type: "is-danger"
          });
        });
    }
  }
};
</script>
